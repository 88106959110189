import { AConfig } from "../classes/AConfig.js";
import { AError } from "../classes/AError.js";
import { AEngine } from "../core/AEngine.js";
import { AErrorWithContext } from "../core/errors.js";
import { AConfigOrm } from "../orm/AConfigOrm.js";
import { ACamel, isVariablePrimitive } from "../utils/tools.js";
import { AConfigManageService } from "./AConfigManageService.js";
import { EVENTS } from "./AEventService.js";
export class AConfigService {
    constructor() {
        this.orm = new AConfigOrm();
        this.config = [];
    }
    // private configFlat: {[cfgPath in AConfigKey]: any}
    get FALLBACK_FLAT() {
        return {
            'general.map': { lat: 50.8278538, lng: 3.2650621, zoom: 19 },
            'general.streetview': { heading: 324.674806324795, pitch: 7.719, zoom: 0.944 },
            'general.centerMapOnClick': false,
            'general.minEnforcingOccupancy': 0.1,
            'general.langBryntum': 'En',
            'general.security.useStrictPasswordPolicy': false,
            'filters.warningThresholdWeeks': 8,
            'filters.enableFilterParkingRightType': false,
            'filters.enableFilterVerifyResult': false,
            'filters.showZonesInsteadOfAreas': false,
            'filters.override': { enabled: false, fromDate: null, fromTime: null, toDate: null, toTime: null },
            'filters.maxResults': { maxResultsMaps: 2000, maxResultsReports: 1000, maxResultsDefault: 250, maxResultsCeiling: 100000 },
            'thematic.occupancy': [{ color: '#32cd32', x: 0 }, { color: '#ff0000', x: 100 }],
            'thematic.visitorRate': [{ color: '#32cd32', x: 0 }, { color: '#ff0000', x: 100 }],
            'thematic.compliancy': [{ color: '#32cd32', x: 0 }, { color: '#ff0000', x: 100 }],
            'thematic.compliancyVisitor': [{ color: '#32cd32', x: 0 }, { color: '#ff0000', x: 100 }],
            'thematic.enforcementIntensity': [{ color: '#32cd32', x: 0 }, { color: '#ff0000', x: 100 }],
            'drawing.chartColors': [{ color: '#3366cc' }, { color: '#dc3912' }, { color: '#ff9900' }, { color: '#109618' }, { color: '#990099' }, { color: '#0099c6' }, { color: '#dd4477' }, { color: '#66aa00' }, { color: '#b82e2e' }, { color: '#316395' }, { color: '#994499' }, { color: '#22aa99' }, { color: '#aaaa11' }, { color: '#6633cc' }, { color: '#e67300' }, { color: '#8b0707' }, { color: '#651067' }, { color: '#329262' }, { color: '#5574a6' }, { color: '#3b3eac' }, { color: '#b77322' }, { color: '#16d620' }, { color: '#b91383' }, { color: '#f4359e' }, { color: '#9c5935' }, { color: '#a9c413' }, { color: '#2a778d' }, { color: '#668d1c' }, { color: '#bea413' }, { color: '#0c5922' }, { color: '#743411' }],
            'drawing.routeSpeed': [{ color: "#dedede", x: 0 }, { color: "#32cd32", x: 3 }, { color: "#ff0000", x: 60 }],
            'drawing.routePrecision': [{ color: '#32cd32', x: 1.0 }, { color: '#ff0000', x: 3.0 }],
            'drawing.tableColors': { error: '#E30039', success: '#07CF00', highlight: '#00afe3' },
            'database.vehicleTypes': ['Car', 'Truck'],
            'database.locationTypes': ['Garbage', 'Other', 'Calibration'],
            'database.parkingRightTypes': ['Day Permit', 'Free Parking'],
        };
    }
    getConfigPaths() {
        return Object.keys(this.FALLBACK_FLAT);
    }
    getConfigPathsGrouped() {
        const cfgGroups = {};
        this.getConfigPaths().map((cfgPath) => {
            const cfgGroup = cfgPath.split('.').shift();
            if (!cfgGroups.hasOwnProperty(cfgGroup)) {
                cfgGroups[cfgGroup] = { cfgPaths: [] };
            }
            cfgGroups[cfgGroup].cfgPaths.push(cfgPath);
        });
        return cfgGroups;
    }
    isPrimitive(cfgPath) {
        return isVariablePrimitive(configService.FALLBACK_FLAT[cfgPath]) ? 1 : 0;
    }
    async autoInit() {
        Events.hardwire(EVENTS.PREFETCH, async () => {
            await this.prefetch().catch(AError.handle);
            await this.migrateFromOld().catch(AError.handle);
        });
    }
    async prefetch() {
        //await this.orm.validate()
        const res = await this.orm.fetchAll();
        let missingPaths = [];
        this.config = [
            ...this.getConfigPaths().filter(cfgPath => {
                return (res.find(item => item.CfgPath === cfgPath) === undefined);
            }).map(cfgPath => {
                missingPaths.push(cfgPath);
                return this.genConfigModel(cfgPath);
            }),
            ...res.toArray()
        ];
        if (missingPaths.length > 0) {
            AEngine.warn(`Loaded fallback config values for:`, missingPaths);
        }
    }
    async reload() {
        const res = await this.orm.fetchAll();
        this.config = [
            ...this.getConfigPaths()
                .filter(cfgPath => res.find(item => item.CfgPath === cfgPath) === undefined)
                .map(cfgPath => this.genConfigModel(cfgPath)),
            ...res.toArray()
        ];
    }
    validate() {
        return this.orm.validate();
    }
    /**
     * Uses configService.FALLBACK_FLAT to generate a config model
     * @param cfgPath key for the config item
     */
    genConfigModel(cfgPath) {
        if (!this.FALLBACK_FLAT.hasOwnProperty(cfgPath)) {
            throw new AErrorWithContext(`AConfigService.genConfigModel failed to generate value with cfgPath:`, cfgPath);
        }
        const CfgValue = configService.FALLBACK_FLAT[cfgPath];
        const cfgModel = {
            CfgPath: cfgPath,
            CfgValue: CfgValue,
            IsPrimitive: configService.isPrimitive(cfgPath),
            ModificationUser: 'ACCCServer',
            ModificationDevice: 'ACCCServer',
            Version: 1
        };
        return cfgModel;
    }
    getConfigItems() {
        return this.config;
    }
    getConfigItem(key) {
        const found = this.config.find(cfgItem => cfgItem.CfgPath === key);
        return (found !== undefined) ? found : this.genConfigModel(key);
    }
    get(key, defaultValue) {
        // if (defaultValue !== undefined) {
        //   return defaultValue
        // }
        // const found = this.config.find(cfgItem => cfgItem.CfgPath === key)
        // if (found !== undefined) {
        //   return found.CfgValue
        // }
        // const configItem = this.genConfigModel(key as AConfigKey)
        // return configItem.CfgValue
        // AEngine.warn(`AConfigService.get("${key}") failed to find a value, defaulting to null`)
        // return null
        // TODO: Remove defaultValue because it will never be used!
        return this.getConfigItem(key).CfgValue ?? defaultValue;
    }
    async getVersioned(key, minVersion) {
        // TODO: Implement minVersion!
        // const cfgItem = await this.orm.findOne({CfgPath: key})
        // if (cfgItem !== undefined) { return cfgItem.CfgValue }
        // const configItem = this.genConfigModel(key as AConfigKey)
        // return configItem.CfgValue
        return this.getConfigItem(key).CfgValue;
    }
    async migrateFromOld() {
        const manageConfigService = AEngine.get(AConfigManageService);
        AEngine.log('===== BEGIN MIGRATION AConfig =====');
        if (AConfig.get(`general.security.useStrictPasswordPolicy`) === true) {
            await manageConfigService.createOrUpdate({
                CfgPath: `general.security.useStrictPasswordPolicy`,
                CfgValue: JSON.stringify(true)
            });
        }
        if (AConfig.get(`general.map.centerOnClick`) === true) {
            await manageConfigService.createOrUpdate({
                CfgPath: `general.centerMapOnClick`,
                CfgValue: JSON.stringify(true)
            });
        }
        var minEnforcingForOccupancy = AConfig.get(`general.minEnforcingForOccupancy`);
        if (minEnforcingForOccupancy !== null) {
            if (!isNaN(Number(minEnforcingForOccupancy)) && typeof minEnforcingForOccupancy === 'number') {
                await manageConfigService.createOrUpdate({
                    CfgPath: `general.minEnforcingForOccupancy`,
                    CfgValue: minEnforcingForOccupancy
                });
            }
        }
        if (AConfig.get(`general.language`)) { // for bryntum
            await manageConfigService.createOrUpdate({
                CfgPath: `general.langBryntum`,
                CfgValue: AConfig.get(`general.language`)
            });
        }
        var map = AConfig.get(`general.map.pos`);
        if (map && map.lat && map.lng && !isNaN(Number(map.lat)) && !isNaN(Number(map.lng))) {
            await manageConfigService.createOrUpdate({
                CfgPath: `general.map`,
                CfgValue: {
                    lat: map.lat,
                    lng: map.lng,
                    zoom: AConfig.get(`general.map.mapZoom`, 19)
                }
            });
        }
        var heading = AConfig.get(`general.map.pov.heading`);
        var pitch = AConfig.get(`general.map.pov.pitch`);
        if (heading && typeof heading === 'number' && !isNaN(heading) && pitch && typeof pitch === 'number' && !isNaN(pitch)) {
            await manageConfigService.createOrUpdate({
                CfgPath: `general.streetview`,
                CfgValue: {
                    heading,
                    pitch,
                    zoom: AConfig.get(`general.map.pov.zoom`, 0.944),
                }
            });
        }
        // ["capacity", "occupancy", "compliancy", "visitorRate", "compliancyVisitor", "enforcementIntensity"]
        const categories = ['Occupancy', 'VisitorRate', 'Compliancy', 'CompliancyVisitor', 'EnforcementIntensity'];
        await Promise.all(categories.map(async (cat) => {
            const bounds = AConfig.get(`drawing & colors.thematic.${ACamel(cat)}.bounds`);
            const colors = AConfig.get(`drawing & colors.thematic.${ACamel(cat)}.colors`);
            if (bounds && colors && bounds.length > 0 && bounds?.length === colors?.length) {
                await manageConfigService.createOrUpdate({
                    CfgPath: `thematic.${ACamel(cat)}`,
                    CfgValue: bounds.map((bound, index) => {
                        return {
                            x: bound,
                            color: colors[index]
                        };
                    })
                });
            }
        }));
        var minWeeksWarning = AConfig.get('filters.minWeeksWarning', 8) ?? 8;
        await manageConfigService.createOrUpdate({
            CfgPath: `filters.warningThresholdWeeks`,
            CfgValue: typeof minWeeksWarning === 'number' ? minWeeksWarning : 8
        });
        if (AConfig.get('filters.enableFilterParkingRightType') === true) {
            await manageConfigService.createOrUpdate({
                CfgPath: `filters.enableFilterParkingRightType`,
                CfgValue: JSON.stringify(true)
            });
        }
        if (AConfig.get('filters.enableFilterVerifyResult') === true) {
            await manageConfigService.createOrUpdate({
                CfgPath: `filters.enableFilterVerifyResult`,
                CfgValue: JSON.stringify(true)
            });
        }
        if (AConfig.get('filters.showZonesInsteadOfAreas') === true) {
            await manageConfigService.createOrUpdate({
                CfgPath: `filters.showZonesInsteadOfAreas`,
                CfgValue: JSON.stringify(true)
            });
        }
        await manageConfigService.createOrUpdate({
            CfgPath: `filters.maxResults`,
            CfgValue: {
                maxResultsMaps: AConfig.get(`filters.maps.maxResults`, 2000),
                maxResultsReports: AConfig.get(`filters.tables.maxResults`, 250),
                maxResultsDefault: AConfig.get(`filters.default.maxResults`, 250),
                maxResultsCeiling: AConfig.get(`filters.maxResultsCeiling`, 100000),
            }
        });
        await manageConfigService.createOrUpdate({
            CfgPath: `drawing.tableColors`,
            CfgValue: {
                error: AConfig.get('drawing & colors.tables.error', '#e20039'),
                success: AConfig.get('drawing & colors.tables.success', '#07cf00'),
                highlight: AConfig.get('drawing & colors.tables.highlight', '#00afe3')
            }
        });
        var precision = {
            bounds: AConfig.get('drawing & colors.precision.bounds', []),
            colors: AConfig.get('drawing & colors.precision.colors', []),
        };
        if (precision.bounds.length === precision.colors.length) {
            await manageConfigService.createOrUpdate({
                CfgPath: `drawing.routePrecision`,
                CfgValue: precision.bounds.map((bound, index) => {
                    return {
                        x: bound,
                        color: precision.colors[index]
                    };
                })
            });
        }
        var speed = {
            bounds: AConfig.get('drawing & colors.speed.bounds', []),
            colors: AConfig.get('drawing & colors.speed.colors', []),
        };
        if (speed.bounds.length === speed.colors.length) {
            await manageConfigService.createOrUpdate({
                CfgPath: `drawing.routeSpeed`,
                CfgValue: speed.bounds.map((bound, index) => {
                    return {
                        x: bound,
                        color: speed.colors[index]
                    };
                })
            });
        }
        await manageConfigService.createOrUpdate({
            CfgPath: `database.vehicleTypes`,
            CfgValue: AConfig.get(`databaseDefinitions.vehicleTypes`, ['Car', 'Truck'])
        });
        await manageConfigService.createOrUpdate({
            CfgPath: `database.locationTypes`,
            CfgValue: AConfig.get(`databaseDefinitions.locationTypes`, ['Garbage', 'Obstruction', 'TrafficSign', 'Other'])
        });
        await manageConfigService.createOrUpdate({
            CfgPath: `database.parkingRightTypes`,
            CfgValue: AConfig.get(`databaseDefinitions.parkingRightTypes`, ['Day Permit', 'Free Parking'])
        });
        if (AConfig.get('filters.overrideFilters') === true) {
            var overrideObj = AConfig.get('filters.override');
            if (Object.keys(overrideObj).length === 4) {
                await manageConfigService.createOrUpdate({
                    CfgPath: `filters.override`,
                    CfgValue: {
                        enabled: false,
                        ...overrideObj,
                    }
                });
            }
        }
        AEngine.log('===== END MIGRATION AConfig =====');
    }
}
