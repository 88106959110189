import { AError } from "../../classes/AError.js";
import { AEngine, sleep } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AFormInstance } from "../../core/form/AFormInstance.js";
import { ATableFormatter } from "../../core/form/table/ATableFormatter.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { AConfigManageService } from "../../services/AConfigManageService.js";
import { ADragService } from "../../services/ADragService.js";
import { EVENTS } from "../../services/AEventService.js";
import { embedJsonInHtml } from "../../utils/json.js";
import { default_order } from "../../utils/table_formatter.js";
import { APascal, ATryFormatDate, formatStringToPascal, initAccordions, isValidJson } from "../../utils/tools.js";
const configManageService = AEngine.get(AConfigManageService);
export class APage {
    constructor() {
        this.cfgGroups = {};
        this.components = [];
        this.cfgGroups = configService.getConfigPathsGrouped();
        $('#Apply').on('click', (e) => {
            const pendingComponents = this.components.filter(c => c.isPendingChange());
            for (let cfgComp of pendingComponents) {
                if (!cfgComp.getFormInstance().validate()) {
                    Alerts.formNotValid();
                    return;
                }
            }
            Loading.waitForPromises(this.showSaveModal(pendingComponents)).catch(AError.handle);
        });
        $('#RefreshButton').on('click', async () => {
            try {
                await Loading.waitForPromises(configService.reload());
                await Loading.waitForPromises(this.refresh());
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
            }
        });
    }
    async init() {
        await configService.validate();
        await this.refresh();
        Events.on(EVENTS.COMPONENT_PENDING_CHANGE_ADDED, (a, b, c) => {
            AEngine.log('COMPONENT_PENDING_CHANGE_ADDED %c->', { a, b, c });
            $('.display-once').remove();
            $('#Apply').prop('disabled', false);
        });
        AEngine.get(ADragService).createDraggableGeneric({ defaultSize: ['66%', '34%'] });
    }
    /**
     * @deprecated
     * @param cfgPath
     * @returns
     */
    renderConfigPath(cfgPath) {
        const cfgPathClean = cfgPath.split('.').slice(1).join('.');
        return APascal(cfgPathClean.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\s+/g, ' ').trim().replace(/([ ])(.)/g, (_, g1, g2) => g1 + g2.toUpperCase()));
    }
    async genEditForm(comp, cfgItem) {
        // const CfgValue = cfgItem.CfgValue ? JSON.stringify(cfgItem.CfgValue, null, 4) : JSON.stringify(configService.FALLBACK_FLAT[cfgItem.CfgPath], null, 4)
        const formData = Object.assign(configManageService.emptyModel, cfgItem, {
            CfgValue: JSON.stringify(cfgItem.CfgValue, null, 4),
            ModificationTime: ATryFormatDate(cfgItem.ModificationTime ?? new Date())
        });
        const form = new AFormInstance({
            ignoreWildcards: false,
            defaultValue: configManageService.emptyModel,
            formInputs: [
                { id: 'CfgPath', type: 'text', minlength: 0, maxlength: 255, disabled: true, width: 'col-12' },
                { id: 'CfgValue', type: 'jsontext', minlength: 0, maxlength: 4096,
                    rows: Math.min(formData.CfgValue.split('\n').length, 8),
                    hasError: (opt) => !isValidJson(opt.formData.CfgValue),
                    autocomplete: false,
                    width: 'col-12'
                },
                { id: 'Version', type: 'number', step: '1', width: 'col-12' },
                { id: 'ModificationUser', type: 'text', minlength: 0, maxlength: 255, disabled: true, width: 'col-12' },
                { id: 'ModificationDevice', type: 'text', minlength: 0, maxlength: 255, disabled: true, width: 'col-12' },
                { id: 'ModificationTime', type: 'text', minlength: 0, maxlength: 255, disabled: true, width: 'col-12' },
            ]
        });
        await form.generate({ translate: false, wrapInColumns: true });
        await form.injectFormData({ formData, triggerChange: true });
        await form.initFormValidation();
        comp.bindValidator(form);
        comp?.$ele().on('aci-change', (e, caller) => {
            // const data = caller.toJSON()
            // console.log('aci-change', cfgItem.CfgPath, data)
            // const $cfgVal = form.$form().find('#CfgValue')
            // const IsPrimitive = (configService.isPrimitive(cfgItem.CfgPath! as AConfigKey) === 1)
            // const newValue = IsPrimitive ? (data + '') : JSON.stringify(data, null, 4)
            // // AEngine.log('aci-change', {form, IsPrimitive, newValue, cfgData})
            // $cfgVal.val(newValue)
            // $cfgVal.trigger('change')
        });
        return form;
    }
    async showSaveModal(pendingComponents) {
        // const changes = compareObjects<AConfigModel, {CfgGroup: string}>(formData, options, { ignore: ['ModificationDevice', 'ModificationTime', 'ModificationUser', 'CfgGroup'] })
        // const {htmlWrapper} = embedJsonInHtml('clsname', changes, { indendation: 2 })
        const events = Alerts.show({
            buttons: ALERT_BUTTONS.yesNo,
            title: ALERT_TITLES.None,
            content: await Translate.get(`Do you want to apply the changes?`),
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                const pendingForms = pendingComponents.map(c => c.getFormInstance());
                for (let form of pendingForms) {
                    const options = form.extractFormData({ cleanData: true, setInternalFormData: true });
                    await Loading.waitForPromises(configManageService.createOrUpdate(Object.assign({}, options, {
                        CfgValue: JSON.stringify(JSON.parse(options.CfgValue))
                    })));
                    form.saveFormData({ cleanData: true });
                }
            }
            catch (err) {
                AError.handle(err);
            }
        });
    }
    async clearComponentForms() {
        this.components = [];
        $('.cfg-content').html(await templateService.render(ATemplates.EmptyList) ?? '');
    }
    async addComponent(cfgComponent) {
        const $content = $('.cfg-content');
        this.components.push(cfgComponent);
        // for (let c of this.components) { }
        const $cfgGroup = $(/*html*/ `
      <div class="column col-12 col-mx-auto cfg-group accordion-wrapper hidden mt-2">
        <h5 class="accordion-toggle" style="cursor: pointer;">
          <i class="icon icon-arrow-right mr-1"></i>
          ${cfgComponent.CfgPath.split('.').map(str => formatStringToPascal(str)).join(' ')}
        </h5>
        <div cfgPath="${cfgComponent.CfgPath}" class="hidable cfg-items-toggle" style="display: none">
        </div>
      </div>
    `);
        $content.append($cfgGroup);
        initAccordions($content, { clickableSelector: '.accordion-toggle' });
        const form = await this.genEditForm(cfgComponent, cfgComponent.getCfgItem());
        form.attachTo($cfgGroup.find('.cfg-items-toggle'));
    }
    async refresh() {
        await this.clearComponentForms();
        const configItems = configService.getConfigItems();
        const cfgMap = {};
        Object.keys(this.cfgGroups).map((keyGroup, i) => {
            const strGroup = APascal(keyGroup);
            if (!cfgMap.hasOwnProperty(strGroup)) {
                cfgMap[strGroup] = {};
            }
            this.cfgGroups[keyGroup].cfgPaths.map(cfgPath => {
                const cfgItem = configService.getConfigItem(cfgPath); // const cfgItem = configItems.find(item => item.CfgPath === cfgPath) ?? configManageService.genConfigModel(cfgPath)
                const cfgTitle = this.renderConfigPath(cfgItem.CfgPath);
                if (cfgMap[strGroup].hasOwnProperty(cfgTitle)) {
                    throw new Error(`Config cfgMap[${strGroup}][${cfgTitle}] has duplicate values in configItems!`);
                }
                cfgMap[strGroup][cfgTitle] = cfgItem;
                return cfgItem;
            }).filter(v => v !== undefined);
        });
        const $cfgGroupArr = await Promise.all(Object.keys(this.cfgGroups).map(async (group, i) => {
            const cfgItems = this.cfgGroups[group].cfgPaths.map(cfgPath => {
                return configItems.find(item => item.CfgPath === cfgPath);
            }).filter(v => v !== undefined);
            const $cfgGroup = $(/*html*/ `
        <div class="column col-10 col-mx-auto cfg-group accordion-wrapper mb-3">
          <h4 class="accordion-toggle" style="cursor: pointer;">
            <i class="icon icon-arrow-right mr-1"></i>
            ${APascal(group)}
          </h4>
          <div class="hidable cfg-items-toggle" style="display: none"></div>
        </div>
      `);
            const $cfgItemArr = await Promise.all(cfgItems.map(async (cfgItem) => {
                const htmlValue = cfgItem.IsPrimitive ? cfgItem.CfgValue : embedJsonInHtml(cfgItem.CfgValue, { wrapHtml: true, indendation: 4 });
                const $cfgItem = $(/*html*/ `
          <div cfg-path="${cfgItem.CfgPath}" class="cfg-item">
            <div class="cfg-title-tmp d-flex" style="justify-content: space-between;">
              <h4>${this.renderConfigPath(cfgItem.CfgPath)}</h4>
              <div class="btn btn-primary btn-sm">Edit</div>
            </div>
            <div class="aci-comp-load" style="cursor: pointer;">${htmlValue}</div>
            <div class="cfg-renderer"></div>
          </div>
        `);
                $cfgItem.find('.aci-comp-load').on('click aci-load', async (e) => {
                    try {
                        $cfgItem.find('.aci-comp-load, .cfg-title-tmp').remove('');
                        const cfgComponent = await configManageService.genEditableComponent(cfgItem);
                        await cfgComponent.attachTo($cfgItem.find('.cfg-renderer'));
                        await Loading.waitForPromises(this.addComponent(cfgComponent));
                    }
                    catch (err) {
                        AError.handle(err);
                    }
                });
                return $cfgItem;
            }));
            // Add cfgItems to group in html
            $cfgGroup.find('.cfg-items-toggle').append($cfgItemArr);
            return $cfgGroup;
        }));
        const $mainView = $('.cfg-components');
        $mainView.html('');
        $mainView.append($(/*html*/ `<div class="columns"></div>`).append($cfgGroupArr));
        $mainView.find('.aci-comp-load').trigger('aci-load');
        initAccordions($mainView, { clickableSelector: '.accordion-toggle' });
        $mainView.find('.accordion-toggle').eq(0).trigger('click');
        await sleep(80);
    }
    async displayFloatingTable(cfgMap) {
        await purgatoryService.buildAndShowInfoWindow({
            data: cfgMap,
            parent: '.part-one',
            greyOutFields: true,
            sorting: default_order(),
            tableFormatter: new ATableFormatter({
                // [Scope: Group]
                "*": {
                    type: 'OBJECT',
                    expanded: true,
                    formatter: {
                        // [Scope: Config Setting Title]
                        "*": {
                            type: 'OBJECT',
                            formatter: {
                                "CfgPath": { type: 'HIDDEN' },
                                "CfgValue": { type: 'DYNAMIC' },
                                "IsPrimitive": { type: 'BOOLEAN' },
                                "Version": { type: 'NUMBER' },
                            }
                        }
                    }
                },
            })
        });
    }
}
export function css() {
    return ( /*html*/`
    <style>
    .cfg-group .form-horizontal .form-switch {
      width: 100%;
      padding-left: 0;
    }
    .cfg-group .form-horizontal .form-switch .form-icon {
      right: 0;
      left: auto;
    }
    .cfg-group .cfg-item form {
      padding: 0 !important;
    }
    .cfg-group .accordion-toggle {
      margin-bottom: 0 !important;
    }
    .cfg-group h5.accordion-toggle { line-height: 26px; }
    .cfg-components > [comp] {
      /*height: 100%;*/
    }
    .cfg-gradient {
      position: relative;
      overflow: hidden;
      height: 120px;
      border-radius: 0.4rem;
    }
    .cfg-render-array {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      line-height: normal;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .cfg-render-array > div {
      flex: 1;
      min-height: 30px;
    }

    .cfg-span-array {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      line-height: normal;
      align-content: flex-end;
      pointer-events: none;
    }

    .cfg-span-array span.column.text-center {
      font-size: 0.7rem;
      font-weight: 500;
      letter-spacing: 2px;
      vertical-align: top;
    }

    .cfg-span-array span.column.text-center:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    .cfg-span-array span.column.text-center:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    .popover .popover-container.popover-large {
      width: 540px;
    }

    .fa.fa-info-circle {
      color: var(--main-color);
    }

    .form-group-datetime>input {
      display: inline-block;
      width: 49.5%
    }

    .form-checkbox.checkbox-left {
      display: inline-block;
      margin: 0;
    }

    .form-checkbox.checkbox-left .form-icon {
      height: 1rem;
      width: 1rem;
      margin: 0
    }

    .form-checkbox.checkbox-left+.form-label {
      display: inline-block;
    }

    .cfg-content {
      display: flex;
      padding: 0.4rem;
      height: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
    }

    .cfg-content > form {
      background: #0000000a;
      padding: 0.4rem;
    }

    .cfg-cursors {
      display: flex;
      position: relative;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      height: 96px;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
    }

    .cfg-gradient-ruler {
      width: 100%;
      height: 0;
    }
    
    .gradient-cursor {
      display: flex;
      position: absolute;
      width: 32px;
      margin-left: -16px;
      text-align: center;
      user-select: none; /* Standard syntax */
      flex-direction: row;
      align-items: flex-end;
    }
    
    .gradient-cursor > div {
      background: #fff;
      box-shadow: 0 1px 3px 0px #00000040;
      transition: box-shadow 0.3s ease-out;
    }
    /*.gradient-cursor:hover > div { box-shadow: 0 2px 8px -1px #000000ad; }*/

    .gradient-cursor.selected { width: 128px; }
    .gradient-cursor .cursor-compact { z-index: 1; width: 32px; }
    .gradient-cursor:not(.selected) .cursor-expanded { display: none; }
    .gradient-cursor .cursor-expanded { border-left: 4px solid #fff; border-right: 4px solid #fff }

    .gradient-cursor:not(.selected) .cursor-check { display: none; }
    .gradient-cursor.selected .cursor-edit { display: none; }

    .gradient-cursor input[type="color"] {
      width: 100%;
      height: 32px;
      padding: 0;
      outline: 0;
      border: 0;
      opacity: 0;
    }

    .gradient-cursor .color-proxy {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 3;
      height: 32px;
      width: 32px;
      left: 0;
      bottom: 32px;
      color: #fff;
      cursor: pointer;
      pointer-events: none;
    }

    .gradient-cursor .cursor-control {
      display: flex;
      width: 32px;
      min-height: 32px;
      justify-content: center;
      align-items: center;
    }

    .gradient-cursor .cursor-row {
      display: flex;
      height: 32px;
      justify-content: flex-start;
      align-items: center;
    }

    .gradient-cursor.cursor-locked { z-index: 1 }
    .gradient-cursor.cursor-locked .cursor-destroy,
    .gradient-cursor.cursor-locked .cursor-grip { display: none; }
    .gradient-cursor:not(.cursor-locked) .cursor-lock { display: none; }
    
    .gradient-cursor .cursor-destroy,
    .gradient-cursor .cursor-edit,
    .gradient-cursor .cursor-check { cursor: pointer; }
    .gradient-cursor .cursor-lock { cursor: not-allowed; }
    .gradient-cursor .cursor-grip { cursor: grab; }
    
    .gradient-cursor.selected .cursor-grip { display: none; }
    .gradient-cursor:not(.selected) .cursor-ruler { display: none; }
    .gradient-cursor.cursor-locked .cursor-ruler { display: none; }

    .show-on-shift {
      display: none;
    }
    .aci-shiftkey .show-on-shift {
      display: flex;
    }
    .aci-shiftkey .hide-on-shift {
      display: none;
    }

    .cfg-components .form-horizontal .form-group {
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 0.4rem;
    }
    .cfg-components .form-horizontal .form-group .form-input {
      flex: 0 200px;
    }

    .cfg-changes-header {
      background: #f9f9f9;
      border-bottom: 1px solid #eee;
    }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div class="flex-child bryntum-container has-footer-2">
      <div class="flex-content">
        <div class="splitter fh" style="position: relative; overflow-y: auto;">
          <div class="drag-section part-one custom-scroll">
            <div class="column col-12 cfg-components bg-main fh pt-2" style="overflow-y: scroll">
            </div>
          </div>

          <div id="separator1" class="drag-seperator separator"></div>

          <div class="drag-section part-two custom-scroll">
            <div class="column col-12 fh" style="position: relative; overflow-y: auto">

              <div class="columns cfg-changes-header" style="height: 59px;">
                <div class="column col-auto col-mx-auto" style="display: flex; align-items: center">
                  <h4 class="text-center mb-0">Pending Changes</h4>
                </div>
              </div>
              
              <div class="columns cfg-content" style="height: calc(100% - 59px)">
                <div template="${ATemplates.EmptyList}"></div>
              </div>

            </div>
          </div>

        </div>
      </div>
      
      <div class="columns col-oneline footer aci form-filters form-horizontal">
        <div class="column col-auto col-ml-auto">
          <button id="Reset" class="btn btn-white btn-shadow-sm">Revert To Defaults</button>
        </div>
        <div class="column col-auto">
          <button id="RefreshButton" class="btn btn-white btn-shadow-sm col-12" style="min-width: 183px">Reload Config</button>
        </div>
        <div class="column col-auto">
          <button id="Apply" class="btn btn-primary" style="min-width: 183px" disabled="disabled">Apply Changes</button>
        </div>
      </div>
    </div>
  `);
}
