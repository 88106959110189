import { AError } from "../../classes/AError.js";
import { AResponse } from "../../classes/AResponse.js";
import { AUrlEncodedImageFromBase64 } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.selection = {};
    }
    async init() {
        FilterManager.load();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh().catch(AError.handle)));
    }
    getLicensePlateDashes(countrycode, lp) {
        if (countrycode !== 'nl') { // https://en.wikipedia.org/wiki/Vehicle_registration_plates_of_the_Netherlands
            throw new Error(`Sitecode not supported yet!`);
        }
        let output = [];
        var prevType = /[0-9]/.test(lp[0]);
        for (let i = 1; i < lp.length; i++) {
            var type = /[0-9]/.test(lp[i]);
            if (prevType !== type) {
                output.push(i - 1);
                prevType = type;
            }
        }
        return output;
    }
    extractPointData(Points) {
        var xArr = Points.map(xy => xy.X);
        var yArr = Points.map(xy => xy.Y);
        const minX = Math.min.apply(null, xArr), maxX = Math.max.apply(null, xArr);
        const minY = Math.min.apply(null, yArr), maxY = Math.max.apply(null, yArr);
        const width = maxX - minX;
        const height = maxY - minY;
        return {
            minX, maxX,
            minY, maxY,
            width, height
        };
    }
    loadImg(options, callback) {
        var seconds = 0, maxSeconds = 10, complete = false, done = false;
        if (options.maxSeconds) {
            maxSeconds = options.maxSeconds;
        }
        function tryImage() {
            if (done) {
                return;
            }
            if (seconds >= maxSeconds) {
                callback({ err: 'timeout' });
                done = true;
                return;
            }
            if (complete && img.complete) {
                if (img.width && img.height) {
                    callback({ img: img });
                    done = true;
                    return;
                }
                callback({ err: '404' });
                done = true;
                return;
            }
            else if (img.complete) {
                complete = true;
            }
            seconds++;
            setTimeout(tryImage, 1000);
        }
        var img = new Image();
        img.onload = () => tryImage();
        img.src = options.src;
        tryImage();
    }
    async refresh() {
        this.selection = {};
        const filters = filterService.saveExplicit();
        FilterManager.setActive(false);
        // const ares = await Loading.waitForPromises(
        //   requestService.fetch({
        //     AssertValues: true,
        //     Query: (/*SQL*/`
        //       SELECT DetectionId, DetectionDeviceId, DetectionTime, LicensePlate, Image, ImageOriginal, PlateBounds, CharBounds, CharConfidences
        //       FROM plateimages
        //       WHERE DetectionTime BETWEEN :FromDate AND :ToDate
        //       LIMIT :Limit
        //     `),
        //     Params: filters
        //   }, {
        //     valueMapper: {
        //       DetectionTime: (dateStr) => new Date(dateStr),
        //       Image: (b64) => AUrlEncodedImageFromBase64(b64),
        //       ImageOriginal: (b64) => AUrlEncodedImageFromBase64(b64),
        //     }
        //   })
        // )
        const res = await Loading.waitForPromises(jsonService.fetch({ url: "docs/corrections-data.json" }));
        const ares = new AResponse(res, {
            addColumns: ['Width', 'Height'],
            valueMapper: {
                DetectionTime: (dateStr) => new Date(dateStr),
                Image: (b64) => AUrlEncodedImageFromBase64(b64),
                ImageOriginal: (b64) => AUrlEncodedImageFromBase64(b64),
            }
        });
        FilterManager.setActive(true);
        if (ares.isEmpty) {
            return Alerts.noResults();
        }
        const $ele = $('#corrections');
        $ele.html('');
        const charMap = {
            '*': [],
            '-': [],
            '[': [],
            ']': [],
        };
        await Promise.all(ares.map(async (item) => {
            let imgEle = await new Promise((resolve, reject) => {
                this.loadImg({ src: item.Image, maxSeconds: 3 }, (res) => {
                    if (res.err)
                        reject(res.err);
                    else
                        resolve(res.img);
                });
            });
            // all characters
            item.Width = imgEle.width;
            item.Height = imgEle.height;
            item.CharDistances = [];
            item.DashBounds = [];
            let lpChars = [...item.LicensePlate];
            lpChars.map((c, i) => {
                if (!charMap.hasOwnProperty(c)) {
                    charMap[c] = [];
                }
                charMap[c].push({ item, bounds: item.CharBounds[i].Points, charIndex: i });
                if (i > 0) {
                    let lbox = this.extractPointData(item.CharBounds[i - 1].Points);
                    let rbox = this.extractPointData(item.CharBounds[i].Points);
                    // let delta = rbox.maxX - lbox.minX
                    let delta = rbox.minX - lbox.maxX;
                    if (delta > 0) { // TODO: Scale 
                        console.log(delta, delta);
                        let bounds = [
                            { X: lbox.maxX, Y: rbox.minY },
                            { X: rbox.minX, Y: rbox.minY },
                            { X: rbox.minX, Y: rbox.maxY },
                            { X: lbox.maxX, Y: rbox.maxY },
                        ];
                        charMap['*'].push({ item, bounds, charIndex: -1 });
                    }
                    // Filter out if character is on newline
                    item.CharDistances.push((delta > 0) ? delta : -1);
                }
            });
            // Loop again to get the biggest character distances
            // let loopCount = MAX_DASHES_IN_LP - item.CharDistances.filter(dist => dist === -1).length
            // let chars = [...item.CharDistances].sort().reverse()
            let dashIndexes = this.getLicensePlateDashes('nl', item.LicensePlate);
            for (let x = 0; x < dashIndexes.length; x++) {
                // let indexFirst = item.CharDistances.indexOf(chars[x])
                let lbox = this.extractPointData(item.CharBounds[dashIndexes[x]].Points);
                let rbox = this.extractPointData(item.CharBounds[dashIndexes[x] + 1].Points);
                if (item.CharDistances[dashIndexes[x]] !== -1) {
                    let bounds = [
                        { X: lbox.maxX, Y: rbox.minY },
                        { X: rbox.minX, Y: rbox.minY },
                        { X: rbox.minX, Y: rbox.maxY },
                        { X: lbox.maxX, Y: rbox.maxY },
                    ];
                    item.DashBounds.push(bounds);
                    charMap['-'].push({ item: item, bounds: bounds, charIndex: -1 });
                }
            }
            // left side => [
            const f = this.extractPointData(item.CharBounds[0].Points);
            charMap['['].push({
                item: item,
                bounds: [
                    { X: 0, Y: 0 },
                    { X: f.minX, Y: 0 },
                    { X: f.minX, Y: item.Height },
                    { X: 0, Y: item.Height }
                ],
                charIndex: -1
            });
            // right side => ]
            const r = this.extractPointData(item.CharBounds[item.CharBounds.length - 1].Points);
            charMap[']'].push({
                item: item,
                bounds: [
                    { X: r.maxX, Y: 0 },
                    { X: item.Width, Y: 0 },
                    { X: item.Width, Y: item.Height },
                    { X: r.maxX, Y: item.Height },
                ],
                charIndex: -1
            });
            // filename: LP_INCORRECT_CORRECT_CONFIDENCE/TIMESTAMP.png
        }));
        Object.keys(charMap).sort().map(char => {
            $ele.append(/*html*/ `<div class="column col-12"><h2>${char}</h2></div>`);
            charMap[char].map(({ item, bounds, charIndex }) => {
                const { minX, minY, width, height } = this.extractPointData(bounds);
                const $col = $(/*html*/ `
          <div class="column col-auto" style="position: relative;">
            <div class="char-img" char="${char}" char-index="${charIndex}" style="width: ${width}px; height: ${height}px; background-image: url(${item.Image}); background-repeat: no-repeat; background-position: ${-minX}px ${-minY}px"></div>
          </div>
        `);
                let $img = $col.find('.char-img');
                $img.on('mouseenter', (e) => {
                    var $info = $('#hover-info');
                    $info.html(/*HTML*/ `
            <div class="columns col-gapless">
              <div class="column col-auto" style="position: relative; align-self: baseline;">
                <img src="${item.Image}">
                <div class="dash-highlight" style="left: ${minX}px; width: ${width}px"></div>
                <div class="dash-highlight" style="left: ${minX}px; width: ${width}px"></div>
                <div class="char-highlight" style="left: ${minX}px; width: ${width}px"></div>
              </div>
              <div class="column">${item.LicensePlate}</div>
            </div>
          `);
                    const $hl = $info.find('.dash-highlight');
                    item.DashBounds.map((bounds, i) => {
                        let $d = $hl.eq(i);
                        let pdata = this.extractPointData(bounds);
                        $d.css('left', pdata.minX);
                        $d.css('width', pdata.width + 'px');
                    });
                    // const $hl = $info.find('.dash-highlight')
                    // let chars = [...item.CharDistances].sort().reverse()
                    // let indexFirst = item.CharDistances.indexOf(chars[0])
                    // let indexSecond = item.CharDistances.indexOf(chars[1])
                    // let lX = this.extractPointData(item.CharBounds[indexFirst].Points).maxX
                    // let rX = this.extractPointData(item.CharBounds[indexFirst+1].Points).minX
                    // $hl.css('left', this.extractPointData(item.CharBounds[indexFirst].Points).maxX)
                    // $hl.css('width', rX - lX + 'px')
                    console.log('characters distances', item.CharDistances, item.LicensePlate);
                });
                $img.on('click', (e) => {
                    if ($img.is('.selected')) {
                        $img.removeClass('selected');
                        delete this.selection[`${item.DetectionDeviceId}-${item.DetectionId}`];
                    }
                    else {
                        $img.addClass('selected');
                        this.selection[`${item.DetectionDeviceId}-${item.DetectionId}`] = { item, bounds, charIndex };
                    }
                    $('#count span').text(Object.keys(this.selection).length);
                });
                $ele.append($col);
            });
        });
    }
}
export function css() {
    return ( /*HTML*/`
    <style>
    #corrections {
      align-items: stretch;
    }
    #corrections > .column {
      min-width: 32px;
    }
    #hover-info {
      overflow-y: auto;
    }
    .char-img {
      border: 1px solid transparent;
      transition: border .15s;
    }
    .char-img:hover,
    .char-img.selected {
      border: 1px solid red;
    }
    .char-highlight,
    .dash-highlight {
      position: absolute;
      background: red;
      height: 3px;
      min-width: 3px;
      bottom: 3px;
    }
    .dash-highlight {
      background: #0000ff;
    }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
    
        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>

      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child has-footer-2">
      <div id="hover-info" class="d-flex" style="height: 200px; border-bottom: 1px solid #f3f3f3">

      </div>
      <div class="flex-content" style="position: relative; height: calc(100% - 200px)">
        <div id="corrections" class="columns col-gapless" style="">
          <div class="column col-auto"></div>
        </div>
      </div>
      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text"><span>0</span> Items Selected</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
