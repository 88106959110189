import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_TEXT, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AConvertToGridColumns, AConvertToGridData, AFormatDate, AShowTable, DeviceMultiOptions, generateTreeDropdown } from "../../utils/tools.js";
export class APage {
    constructor() {
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    async init() {
        FilterManager.load();
        await Loading.waitForPromises([
            generateTreeDropdown('#AllDeviceMulti', DeviceMultiOptions('all-devices'), { useIndices: false, attributeToUse: 'unificationindex' }),
        ]);
    }
    async changePageState(opt) {
        const $tab = $(`[tab="${opt.tab}"]`);
        if ($tab.length === 0) {
            AError.handle(`Couldn't find tab [tab="${opt.tab}"]`);
        }
        $tab.trigger('click');
        //   if (opt.resetEditing) {
        //     if (this._editing) {
        //       await Loading.waitForPromises(this.reloadListAndFilters(this._editing.data.Name))
        //     }
        //     this._editing = undefined
        //   }
        //   this.onStateChangeMap[opt.tab](opt.tab)
    }
    refresh() {
        FilterManager.setActive(false);
        const filters = FilterManager.saveExplicit();
        const conditions = [
            'Time BETWEEN :FromDate AND :ToDate',
            filters.AllDeviceMulti ? `DeviceId ${FilterManager.buildQueryFindInArray(filters.AllDeviceMulti)}` : null
        ].filter(v => v != null);
        return Loading.waitForPromises(requestService.query({
            Query: ( /*SQL*/`
          SELECT
            IF(a.Status = 'Disconnected',
              (SELECT SessionId FROM sessionstatus f WHERE (f.SessionStatusId < a.SessionStatusId AND f.Time BETWEEN DATE_SUB(a.Time, INTERVAL 7 DAY) AND  a.Time AND f.DeviceId=a.DeviceId AND f.DeviceName=a.DeviceName) ORDER BY SessionStatusId DESC LIMIT 1),
              (a.SessionId)
            ) AS SessionId,
            Time,
            DeviceName,
            User,
            ComState as ComStateKey,
            ComState,
            Status as StatusKey,
            Status,
            StatusString
          FROM sessionstatus a
          WHERE ${conditions.join(' AND ')}
          ORDER BY SessionStatusId ASC
          LIMIT :Limit
        `),
            Params: filters,
            Language: Language,
            Translate: ["DeviceName", "Status", "ComState", "StatusString"]
        })).then((response) => {
            this.changePageState({ tab: filters.TableStyle });
            FilterManager.setActive(true);
            if (response.Rows.length === 0) {
                return Alerts.noResults();
            }
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: AConvertToGridColumns(response, {
                    "*": COLUMN_HIDDEN,
                    Time: COLUMN_DATETIME,
                    DeviceName: COLUMN_TEXT,
                    User: COLUMN_TEXT,
                    ComState: { htmlEncode: false, renderer: (opt) => `<div class="${opt.record.ComStateKey}">${opt.value}</div>` },
                    Status: { htmlEncode: false, renderer: (opt) => `<div class="${opt.record.StatusKey}">${opt.value}</div>` },
                    StatusString: COLUMN_TEXT,
                }),
                data: AConvertToGridData(response, {
                    Time: DATA_DATETIME
                })
            });
            let StatusIndex = -1;
            let ComStateIndex = -1;
            let StatusKeyIndex = -1;
            let ComStateKeyIndex = -1;
            let Html = "";
            let thead = '<tr>';
            if (response.ColumnsTranslated === null || response.ColumnsTranslated.length !== response.Columns.length) {
                response.ColumnsTranslated = response.Columns;
            }
            for (let i = 1; i < response.Columns.length; i++) {
                if (response.Columns[i] == "Status") {
                    thead += "<th>" + response.ColumnsTranslated[i] + "</th>";
                    StatusIndex = i;
                }
                else if (response.Columns[i] == "ComState") {
                    thead += "<th>" + response.ColumnsTranslated[i] + "</th>";
                    ComStateIndex = i;
                }
                else if (response.Columns[i] == "StatusKey")
                    StatusKeyIndex = i;
                else if (response.Columns[i] == "ComStateKey")
                    ComStateKeyIndex = i;
                else
                    thead += "<th>" + response.ColumnsTranslated[i] + "</th>";
            }
            thead += '</tr>';
            let LastSessionId = null;
            for (let r = 0; r < response.Rows.length; r++) {
                if (LastSessionId != response.Rows[r][0]) {
                    Html += "<tr>";
                    for (let c = 1; c < response.Rows[r].length; c++) {
                        Html += "<td><hr></td>";
                    }
                    Html += "</tr>";
                    LastSessionId = response.Rows[r][0];
                }
                Html += "<tr>";
                let StatusKey = "";
                let ComStateKey = "";
                for (let c = 1; c < response.Rows[r].length; c++) {
                    if (response.Rows[r][c] == null) {
                        Html += "<td>-</td>";
                    }
                    else {
                        let Value = response.Rows[r][c];
                        if (typeof Value == "string" && Value.charAt(4) == '-' && Value.charAt(7) == '-' && Value.charAt(10) == 'T') {
                            Value = AFormatDate(new Date(Value));
                        }
                        if (c == ComStateIndex) {
                            Html += "<td class='" + ComStateKey + "'>" + Value + "</td>";
                        }
                        else if (c == StatusIndex) {
                            Html += "<td class='" + StatusKey + "'>" + Value + "</td>";
                        }
                        else if (c == StatusKeyIndex) {
                            StatusKey = Value;
                        }
                        else if (c == ComStateKeyIndex) {
                            ComStateKey = Value;
                        }
                        else {
                            Html += "<td>" + Value + "</td>";
                        }
                    }
                }
                Html += "</tr>";
            }
            const $table = $(`<table class="table-session"><thead>${thead}</thead><tbody>${Html}</tbody></table>`);
            const $content = $('#table-oldstyle');
            $content.html('');
            $content.append($table);
        }).catch(AError.handle);
    }
}
export function css() {
    return ( /*html*/`
    <style>
    #Rapport table {
      width: 100%;
      overflow: visible;
    }

    #Rapport table th {
      text-align: left;
    }

    #Rapport table img {
      width: 4.5vw;
      height: 1.5vw;
    }

    #Rapport .table-session tr td:first-child,
    #Rapport .table-session tr th:first-child {
      padding-left: 15px;
    }
    
    #Rapport .table-session tr td:last-child,
    #Rapport .table-session tr th:last-child {
      padding-right: 15px;
    }
    
    #Rapport .table-session thead > tr {
      background: #f9f9f9;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
    }
    
    #Rapport .table-session thead > tr > th {
      border-left: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      padding: 12px 15px;
    }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
        
        <div class="form-group">
          <label class="form-label" for="AllDeviceMulti">Device</label>
          <div id="AllDeviceMulti" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
        
        <div class="form-group">
          <label class="form-label" for="TableStyle">Style</label>
          <select class="form-select" id="TableStyle">
            <option value="compact">Compact</option>
            <option value="uniform">Uniform</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>

    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div class="flex-content">
        <div class="aci-tabs tabs-flex tabs-sticky tabs-fixed-md top-zero sharp-border hidden" tabgroup="view">
          <button class="aci-tab active" tab="compact">
            <span>Compact</span>
          </button>
          <button class="aci-tab" tab="uniform">
            <span>Uniform</span>
          </button>
        </div>

        <div class="columns col-gapless fh">
          <div class="column col-12">
            <div tabgroup="view" tabview="compact" class="fh">
              <div template="${ATemplates.WaitingForInput}"></div>
              <div aci-show-on-input="true" id="table-oldstyle"></div>
            </div>
            <div tabgroup="view" tabview="uniform" class="fh">
              <div template="${ATemplates.WaitingForInput}"></div>
              <div aci-show-on-input="true" id="table-bryntum" class="fh hidden" style="min-height: 300px;"></div>
            </div> 
          </div>
        </div>
      </div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Records</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
