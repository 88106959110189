export const JSON_FILES = {
    MENU: '/menu.json',
    SETTINGS: '/settings.json',
    LANGUAGE: '/language.json',
    BUILD_INFO: '/build-info.json'
};
// @executeOrder(1)
// @yieldForAutoInit
export class AJsonService {
    constructor(cache = {}) {
        this.cache = cache;
    }
    /**
     * Init function
     */
    async autoInit() { }
    /**
     * Caches copy of json data to service
     */
    addToCache({ url, data }) {
        this.cache[url] = JSON.parse(JSON.stringify(data));
    }
    hasCache({ url }) {
        return this.cache.hasOwnProperty(url);
    }
    fetchAll({ cache = true } = { cache: true }) {
        return Promise.all(Object.values(JSON_FILES).map(url => {
            return this.fetch({ url, cache });
        }));
    }
    /**
     * Gets copy of previously fetched json from cache
     */
    getFromCache({ url }) {
        if (!this.hasCache({ url })) {
            throw new Error(`URL "${url}" COULDN'T BE FOUND IN CACHE!`);
        }
        return JSON.parse(JSON.stringify(this.cache[url]));
    }
    /**
     * Fetches json file from web url (ex: "Language.json")
     */
    fetch({ url, cache = false }) {
        return new Promise((resolve, reject) => {
            if (cache && this.hasCache({ url })) {
                return resolve(this.getFromCache({ url }));
            }
            $.getJSON(url, (data) => {
                if (cache === true) {
                    this.addToCache({ url, data });
                }
                resolve(data);
            }).fail((res) => {
                reject(new Error(res.responseText));
            });
        });
    }
}
AJsonService.JSON_FILES = JSON_FILES;
